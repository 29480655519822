import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2342af15"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: "icon-font",
    "aria-hidden": "true",
    style: _normalizeStyle(_ctx.iconSize)
  }, [
    _createElementVNode("use", { "xlink:href": _ctx.iconName }, null, 8, _hoisted_1)
  ], 4))
}