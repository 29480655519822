<template>
  <svg class="icon-font" aria-hidden="true" :style="iconSize">
    <use :xlink:href="iconName"></use>
  </svg>
</template>
<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'IconSvg',
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
    },
  },
  setup(props) {
    const iconName = computed(() => `#${props.name}`);

    const iconSize = computed(() => {
      if (typeof props.size === 'undefined') {
        return undefined;
      }
      if (/^\d+$/.test(props.size.toString())) {
        const v = `${props.size}px`;
        return {
          width: v,
          height: v,
        };
      }
      if (typeof props.size === 'string') {
        return {
          width: props.size,
          height: props.size,
        };
      }
      return undefined;
    });
    return {
      iconName,
      iconSize,
    };
  },
});
</script>

<style scoped>
.icon-font {
  width: 24px;
  height: 24px;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.icon-font:focus {
  outline: 0 !important;
}
</style>
