const loadmore = (app) => {
  app.directive('loadmore', {
    beforeMount(el, binding) {
      const DOM = el.querySelector('.el-select-dropdown .el-select-dropdown_ wrap');
      console.log(DOM);
      if (DOM) {
        DOM.addEventListener('scroll', function () {
          const CONDITION = this.scrollHeight - this.scrollTop - 1 <= this.clientHeight;
          if (CONDITION) binding.value();
        });
      }
    },
  });
};

export default loadmore;
