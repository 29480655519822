import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import loadMore from './utils/directive';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import IconFont from './views/components/IconFont.vue';
import JsonSchemaEditor from 'json-schema-editor-vue3';
import 'json-schema-editor-vue3/lib/json-schema-editor-vue3.css';
import JsonViewer from 'vue-json-viewer';

createApp(App)
  .use(router)
  .use(loadMore)
  .use(ElementPlus, {
    locale: zhCn,
  })
  .use(JsonSchemaEditor)
  .use(JsonViewer)
  .component('IconFont', IconFont)
  .mount('#app');
